export default {
  register: "Register",
  welcome: "Welcome",
  phone: "Phone number",
  agreeTermAndCondition: "Agree terms & conditions",
  back: "Back",
  termAndCondition: "Terms and conditions",
  cancel: "Cancel",
  confirm: "Confirm",
  required: "Please fill in this field",
  zipcode: "Zip code",
  amphur: "Sub-district",
  district: "District",
  province: "Province",
  save: "Save",
  close: "Close",
  name: "Name",
  surname: "Surname",
  birthDay: "Birthdate",
  gender: "Gender",
  educationLevel: "Education level",
  jobLevel: "Job level",
  jobPosition: "Job position",
  department: "Department",
  company: "Company",
  address: "Address",
  enterRegister: "Register",
  management: "Management",
  manager: "Manager",
  supervisor: "Supervisor",
  officer: "Officer/Staff",
  worker: "Worker/Operator",
  doctorate: "Doctorate",
  masterDegree: "Master's degree",
  bachelorDegree: "Bachelor's degree",
  highVocational: "Higher vocational certificate",
  technicalVocational: "Technical vocational certificate",
  vocational: "Vocational certificate",
  highSchool: "High school",
  secondarySchool: "Secondary school",
  primarySchool: "Primary school",
  lowerPrimary: "Lower than primary school",
  male: "Male",
  female: "Female",
  other: "Other",
  profile: "Profile",
  id: "ID",
  nameSurname: "Name-Surname",
  sendRefCodeOtp: "Ref Code",
  requestOtpAgain: "Request OTP Again",
  edit: "Edit",
  add: "Add",
  addressSendReward: "Delivery Address",
  addressDetail: "Address Details",
  selectDefautAddress: "Default Address",
  addressEdit: "Edit Address",
  addressAdd: "Add",
  defaultValue: "Default Value",
  hello: "Hello K.",
  coinTotal: "Total Points",
  homePage: "Home",
  activityPage: "Activities",
  redemptPage: "Redeem Rewards",
  dealPage: "Special Deals",
  invitePage: "Invite Friends",
  addressPage: "Delivery Address",
  favoritePage: "Favorites",
  cancelShipment: "Cancel Shipment",
  couponPage: "My Coupons",
  statusDeliveryPage: "Check Reward Status",
  logout: "Logout",
  categoryAll: "All",
  categoryRecommend: "Recommend",
  categoryFood: "Food",
  categoryElectrical: "Electronic",
  categoryAppliance: "Appliance",
  categoryOther: "Other",
  dealBrand: "Hot Deals from Top Brands!!",
  deal: "Deal Now!",
  totalValue: "Remaining Quantity",
  detail: "Details",
  youWant: "Are you want ",
  delete: "Delete",
  favoriteText: "in your favorite",
  waitingLoad: "Please Wait",
  expireTime: "Expired",
  coin: "Points",
  surveyRegister: "Registration form",
  later: "Later",
  redeem: "redeem",
  redeemPoint: "Redeem used",
  confirmRedeem: "Are you sure to redeem use",
  confirmAdd: "Are you would like to add",
  inYourFav: "to your favorites",
  confirmDelete: "Are you sure to delete",
  fromYourFav: "from your favorites",
  balance: "Balance",
  inviteFrindDetail:
    "Simply invite your friends to use the app. When your friend successfully registers,",
  received: "received",
  inviteFriend: "Invite Friends",
  inviteFriendPhoneText: "Enter the phone number you want to invite",
  fillPhone: "Enter Friend's Phone Number",
  historyRedeem: "Redemption History",
  historyCode: "ประวัติการส่งรหัส",
  coinReceived: "Points Received",
  coinUsed: "Points Used",
  activityAll: "All Activities",
  expireDate: "Expiration Date",
  note: "Note",
  accessCamera: "Please grant permission to access the camera",
  checkIn: "Check-in",
  activityExpireDate: "Activity Expiration Date",
  collectCoin: "Collect Points",
  activityJoin: "Join Activity",
  activityQr: "Scan QR Code",
  activitySurvey: "Take Survey",
  activityUpload: "Upload Activity Results",
  activityScanQr: "SCAN QR",
  sendSurvey: "Send Survey",
  uploadFile: "Upload File",
  sizeFile: "File size should not exceed 10 MB",
  activityStatusJoin: "Joined Activities",
  activityStatusWait: "Pending Activities",
  activityStatusSuccess: "Successful Activities",
  activityStatusReject: "Failed Activities",
  activityStatusAll: "Activities All",
  registerToReceive: "Register",
  confirmUseCoupon: "Confirm to use the coupon",
  noteUseCoupon:
    "We recommend that you use the coupon when you are at the store to prevent losing your rights.",
  noteAlertUseCoupon:
    "The code will be considered used immediately after you click claim.",
  codeDiscount: "Discount Code",
  errorQr: "Show this if the rendering fails.",
  cancelRedemption: "Do you want to cancel the transaction?",
  cancelRedemptionNote:
    "You will not receive point if you cancel the transaction.",
  statusDelivery: "Reward Delivery Status",
  statusSendIn7Day: "Delivered within 7 days",
  statusDeliverySuccess: "Delivered successfully",
  statusDeliveryCancel:"Cancel delivery",
  trackingNumber: "Tracking number",
  receiveReward: "Receive reward",
  copyText: "Copied",
  confirmReceiveReward: "Confirm to receive reward",
  activitySuggest: "Recommended Activities",
  pageNotFound: "Not Found Page",
  goBackPage: "Go to Back Page",
  goToLogin: "Go To Login Page",
  changeLanguage: "Language",
  all: "all",
  join: "join",
  success: "success",
  failed: "failed",
  waiting: "waiting",
  pending: "Pending",
  sent: "Sent",
  delivered: "Delivered",
  textRequireAgain: "Request a new otp code again within : ",
  welcomeActivity: "Welcome to join activity",
  errQr: "Invalid Qr Code, please try again.",
  requireExam: "Please complete the questionnaire.",
  uploadSuccess: "The result has been sent.",
  uploadWaiting: "An inspection will be conducted within 7 days.",
  errDupActivity:
    "You have completed this task. Please select another activity.",
  errExpireActivity: "Sorry, the activity has expired.",
  errFileUpload: "File size should not exceed 10 mb.",
  expireDateUse: "expire date",
  registerPage: "Registration page",
  shareActivity: "Share Activity",
  goToDeal: "Go to Deal",
  goToMission: "Go to Activity",
  menuActivity: "Activity",
  menuDeal: "Special Deals",
  menuRedeem: "Redeem Rewards",
  menuCoupon: "My Coupons",
  privacyPolicy: "Privacy Policy",
  favoriteCategory: "Favorite",
  deviceCategory: "Electronic Devices",
  accessoryCategory: "Electronic Accessories",
  healthCategory: "Health & Beauty",
  foodCategory: "Food & Beverage",
  babyCategory: "Baby & Toys",
  petCategory: "Pets",
  homeCategory: "Home & Lifestyle",
  menCategory: "Men's Fashion",
  womenCategory: "Women's Fashion",
  sportCategory: "Sports",
  travelCategory: "Travel",
  reward: "Reward",
  missionComing: "Mission Coming",
  startDate: "startDate",
  endDate: "endDate",
  home: "Home",
  activity: "Activity",
  tracking: "Tracking",
  account: "Account",
  historyCoupon: "Coupon History",
  rewardSoHot: "รางวัลสุดฮอต",
  hotMission: "กิจกรรม กำลังมาแรง",
  recommendMission: "กิจกรรม แนะนำ",
  doneDetailMission: "รายละเอียดการเข้าร่วมกิจกรรม",
  detailMission: "รายละเอียดกิจกรรม",
  newActivityUpload: "ส่งข้อมูลอีกครั้ง",
  errNotTimeForActivity: "ยังไม่ถึงเวลาเล่นกิจกรรม",
  news: "ข่าวสาร",
  friendRecommend: "เพื่อนแนะนำ",
  friendRequest: "คำร้องขอ",
  friendList: "เพื่อนของฉัน",
  missionLimitReached: "สิทธิเต็มแล้ว",
  rewardLimitReached: "สิทธิเต็มแล้ว",
  missionComingSoon: "เร็วๆนี้",
  missionTimeCannotJoin: "อยู่นอกช่วงเวลากิจกรรม",
  couponExpired: "คูปองหมดอายุ",
  conditionCoin: "ตามเงื่อนไข",
  agree: "ยินยอม",
  disagree: "ไม่ยินยอม",
  activityHeartToHeart: "send message",
  rewardComingSoon: "เร็วๆนี้",
};
