import axios from "axios";
import router from "@/router";
import Swal from "sweetalert2/dist/sweetalert2.js";
import i18n from "../plugins/i18n";
import { detectReferrer } from "./general-function";

let userStorage;


const BackendService = axios.create({
  baseURL: process.env.VUE_APP_MICROSITE_SERVICE ,
  withCredentials: true,
  headers: {
    "Content-type": "application/json",
    Accept: "application/json",
  },
});

BackendService.interceptors.request.use(async (config) => {
  let token = "";
  try {
    userStorage = localStorage.getItem("token");
    token = userStorage;
    //set lang
    config.headers["X-localization"] = i18n.locale;
  } catch (error) {
    console.log(error);
  }
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  if (config.url?.includes("submitTransaction")) {
    const referrer = detectReferrer("referrer");
    config.data.referrer = referrer;
  }

  return config;
});

BackendService.interceptors.response.use(undefined, function(error) {
  if (error) {
    console.log("error :>> ", error);
    if (error.response.status === 401) {
      console.log('hi :>> error.response.status === 401', );
    }
    if (error.response.status === 400) {
      Swal.fire({
        icon: "error",
        // title: "Oops! error",
        text: error.response.data.message,
        showConfirmButton: true,
        confirmButtonColor: "red",
        confirmButtonText: "ตกลง",
      });
    }
    if (error.response.status === 404) {
      return router.push({ name: "page-not-found" });
    }
    throw error;
  }
});

export default BackendService;
