<template><div></div></template>

<script>
import moment from "moment";

export default {
  mounted() {
    const dtn = moment();
    const currentYear = dtn.month() < 1 ? dtn.year() - 1 : dtn.year();
    const startDate = moment()
      .year(currentYear)
      .month(10)
      .date(1)
      .startOf("day");
    const endDate = moment()
      .year(currentYear + 1)
      .month(0)
      .date(31)
      .endOf("day");

    if (dtn.isBetween(startDate, endDate, undefined, "[]")) {
      const snowflake = "❄";
      const body = document.body;

      const createSnowflake = () => {
        const snow = document.createElement("div");
        snow.classList.add("snowflake");
        snow.textContent = snowflake;
        snow.style.left = `${Math.random() * 100}vw`;
        snow.style.fontSize = `${Math.random() * 2 + 0.5}em`;
        snow.style.fontWeight = `700`;
        snow.style.animationDuration = `${Math.random() * 3 + 8}s`; // ความเร็ว
        body.appendChild(snow);

        setTimeout(() => {
          snow.remove();
        }, 10000);
      };

      setInterval(createSnowflake, 800); // สร้างหิมะทุก 800ms
    }
  },
};
</script>

<style>
.snowflake {
  position: fixed;
  top: -10px;
  z-index: 9999;
  color: #e3f2fd;
  /* color: white; */
  font-size: 1em;
  animation: fall 10s linear infinite, sway 5s ease-in-out infinite;
}

@keyframes fall {
  0% {
    top: -10px;
    opacity: 1;
  }
  100% {
    top: 100vh;
    opacity: 0;
  }
}

@keyframes sway {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  }
}
</style>