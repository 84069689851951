<template>
  <div>
    <v-dialog v-if="dialog" v-model="dialog" width="400px" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h5"> {{ userNps.nps_question_text || "" }}</span>
        </v-card-title>
        <v-card-text class="px-0">
          <v-row no-gutters justify="center">
            <v-col cols="auto" class="pa-0">
              <v-rating
                v-model="rating"
                hover
                :length="10"
                :size="30"
                color="#FFC107"
                background-color="#FFC107"
                @change="handleChangeRating"
              />
            </v-col>
          </v-row>
          <v-row
            v-if="userNps.nps_is_use_comment_box"
            no-gutters
            class="px-4 pt-5"
          >
            <v-col cols="12" class="px-0">
              <v-textarea
                :color="site.color"
                v-model="comment"
                placeholder="ความคิดเห็น"
                :rows="3"
                class="rounded-lg"
                dense
                hide-details
                outlined
                no-resize
                background-color="white"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :color="site.color" outlined @click="handleCancel()">
            ข้าม
          </v-btn>
          <v-btn
            :color="site.color"
            class="white--text"
            depressed
            @click="handleConfirm()"
          >
            ยืนยัน
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Axios from "@/config/customer-service";
import { mapState } from "vuex";

export default {
  inject: ["isAppLoading"],
  data() {
    return {
      rating: 10,
      comment: "",
      dialog: false,
    };
  },
  methods: {
    handleCancel() {
      this.onConfirmNps("skip");
    },
    handleConfirm() {
      this.onConfirmNps("complete");
    },
    handleChangeRating(param) {
      console.log(param);
    },
    onConfirmNps(status) {
      this.isAppLoading(true);
      Axios.post("confirmNps", {
        nps: this.rating,
        comment: this.comment,
        status: status,
      })
        .then(() => {
          // this.dialog = false;
        })
        .catch((err) => {
          console.log("onConfirmNps", err);
        })
        .finally(() => {
          this.rating = 10;
          this.comment = "";
          this.$store.commit("defaultSystem/SET_USER_NPS", {
            nps_alert: false,
          });
          this.dialog = false;
          this.isAppLoading(false);
        });
    },
  },
  created() {},
  computed: {
    ...mapState("defaultSystem", ["site", "userNps"]),
  },
  watch: {
    userNps: {
      handler() {
        if (this.userNps?.nps_alert) {
          if (this.userNps?.nps_condition == "app") {
            const dtn = new Date();
            const dtnRef = new Date(this.userNps.nps_timestamp);
            const nps_condition_time =
              this.userNps.nps_condition_time * 60 * 1000;
            const diff = dtn.getTime() - dtnRef.getTime();
            if (diff > nps_condition_time) {
              this.dialog = true;
            }
          } else if (this.userNps?.nps_condition == "activity") {
            this.dialog = true;
          }
        } else {
          this.dialog = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
::v-deep .v-rating .v-icon {
  padding: 0 !important;
}
::v-deep .v-dialog {
  min-width: 300px !important;
}
</style>
