import liff from "@line/liff";
import Axios from "@/config/customer-service";
import store from "@/store";
import AxiosBackend from "@/config/backend-service";
import i18n from "../plugins/i18n";

const urlRedirect = store.state.login.urlRedirect;

async function getSiteTemplate() {
  return new Promise((resolve) => {
    AxiosBackend.get("site/getSiteTemplate", {
      params: {
        url: " https://" + window.location.hostname,
      },
    })
      .then(({ data }) => {
        if (data.status == "success") {
          store.commit("defaultSystem/SET_SITE", data.data);
          const vuex = localStorage.getItem("vuex");
          i18n.locale = JSON.parse(vuex).defaultSystem.site.language;
          resolve(data.data.liff_id);
        }
      })
      .catch((err) => {
        throw err;
      });
  });
}
async function setToken(accessToken) {
  return new Promise((resolve) => {
    localStorage.setItem("token", accessToken);
    resolve();
  });
}
async function setProfile(profile) {
  return new Promise((resolve) => {
    store.commit("defaultSystem/SET_LINE_USER", profile);
    resolve();
  });
}


export const loginLineUser = async (to, form, next) => {
  try {
    let liff_id = await getSiteTemplate();
    const inviteCode = to.query.invite === undefined ? "" : to.query.invite;

    if (!liff_id || liff_id == "-") {
      await next({ name: "login-bypass" });
      return;
    }

    let checkTimeOut = true;
    setTimeout(() => {
      if (checkTimeOut) {
        alert("กรุณาปิดหน้าต่างเว็บไซต์ และเข้าใหม่อีกครั้ง");
      }
    }, 5000);
    await liff.init({ liffId: liff_id ,  withLoginOnExternalBrowser: true}).catch((err) => {
      throw err;
    });
    checkTimeOut = false;

    if (!liff.isLoggedIn()) {
      // query invite
      if (inviteCode) {
        store.commit("friend/SET_INVITE", inviteCode);
      }
      liff.login({
        redirectUri: window.location.href,
      });
    }

    store.commit("defaultSystem/SET_USER", { role: "Customer" });
    const accessToken = await liff.getAccessToken();
    const profile = await liff.getProfile();

    await setToken(accessToken);
    await setProfile(profile);

    if (profile) {
      Axios.get("checkUserIsRegisteredLine", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          if (res.data.status == "registered") {
            localStorage.setItem("token", res.data.data.access_token);

            setTimeout(() => {
              if (urlRedirect) {
                return next({
                  path: urlRedirect,
                })
              }
              next({ name: "main" })
            }, 1000);
          }
          if (res.data.status == "not_registered")
            return next({ name: "checknumberLine" });
          if (res.data.status == "unauthorized")
            return next({ name: "checknumberLine" });
        })
        .catch((err) => {
          console.log("err :>> ", err);
        });
    }
  } catch (error) {
    console.log("error :>> ", error);
  }
};
