import AxiosBackend from "@/config/backend-service";
import Axios from "@/config/customer-service";
import store from "@/store";

function detectReferrer(mode) {
    const friend = store?.state?.friend;
    const platform = friend?.platform || null;
    let referrer = "Website";
    const document_referrer = document.referrer;
    if (document_referrer) {
        if (document_referrer?.includes("facebook")) {
            referrer = "Facebook";
        } else if (document_referrer?.includes("instagram")) {
            referrer = "Instagram";
        }
    }
    if (referrer == "Website") {
        const userAgent = navigator.userAgent;
        // alert(userAgent)
        const isFacebookBrowser = /FB_IAB|FBAN|Orca-Android/.test(userAgent);
        if (userAgent.includes("Line")) {
            referrer = "Line";
        } else if (isFacebookBrowser) {
            referrer = "Facebook";
        }
    }
    if (mode == "param") {
        referrer = platform || "Website";
    } else if (mode == "referrer") {
        // no action
    } else {
        if (platform) {
            referrer = platform;
        }
    }
    return referrer;
}

function detectBrowser() {
    const userAgent = navigator.userAgent;

    if (userAgent.includes("Line")) {
        return "LINE In-App Browser";
    } else if (userAgent.includes("Chrome")) {
        return "Google Chrome";
    } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
        return "Safari";
    } else if (userAgent.includes("Firefox")) {
        return "Firefox";
    } else if (userAgent.includes("MSIE") || userAgent.includes("Trident")) {
        return "Internet Explorer";
    } else if (userAgent.includes("Edg")) {
        return "Microsoft Edge";
    } else {
        return "Unknown Browser";
    }
}

function addParamAndReload(to) {
    let check = 'none';
    const useLineBrowser = false;
    if (useLineBrowser) return check;
    const url = new URL(window.location.href);
    const browser = detectBrowser();
    const list = allStorage();
    const token = list.find(x => x.key == "token");
    const defaultSystem = store?.state?.defaultSystem;
    const customer = defaultSystem.customer;
    const toPath = to.path;
    const watchListPage = ["/", "/checknumberLine", "/detail-user-otp"]
    // ========== ========== ========== ==========
    if (token && customer?.code &&
        !watchListPage.includes(toPath)) {
        if (!url.searchParams.has("openExternalBrowser")) {
            if (browser == "LINE In-App Browser") {
                Axios.get("getProfile").then(({ data }) => {
                    if (data.status == "success") {
                        check = 'active';
                        url.searchParams.set("openExternalBrowser", "1");
                        url.searchParams.set("token", token.value);
                        url.pathname = toPath;
                        window.location.href = url.toString();
                    }
                });
            }
        } else {
            if (url.searchParams.has("openExternalBrowser") && url.searchParams.has("token")) {
                if (browser != "LINE In-App Browser") {
                    check = 'active';
                    url.searchParams.delete("openExternalBrowser");
                    url.searchParams.delete("token");
                    url.pathname = toPath;
                    if (toPath == "/") {
                        url.pathname = "/main";
                    }
                    window.location.href = url.toString();
                }
            }
        }
    } else {
        if (url.searchParams.has("openExternalBrowser") && url.searchParams.has("token")) {
            check = 'active';
            const paramToken = url.searchParams.get("token")
            localStorage.setItem("token", paramToken)
            store.commit("defaultSystem/SET_USER", { role: "Customer" })
            url.searchParams.delete("openExternalBrowser");
            url.searchParams.delete("token");
            url.pathname = toPath;
            if (toPath == "/") {
                url.pathname = "/main";
            }
            window.location.href = url.toString();
        }
    }
    return check;
}

function allStorage() {
    let values = [];
    let keys = Object.keys(localStorage);

    for (let i = 0; i < keys.length; i++) {
        let detail = {
            key: keys[i],
            value: localStorage.getItem(keys[i]),
        }
        values.push(detail);
    }
    return values;
}

function generateBrowserFingerprint() {
    const browser = detectBrowser();
    const screenSize = window.screen.width + "x" + window.screen.height;

    // สร้าง fingerprint โดยการรวมข้อมูลและทำเป็น hash เบื้องต้น
    const fingerprint = `${browser}|${screenSize}`;
    return fingerprint;
}

async function logVisit(to, param) {
    try {
        let body = {
            "site_id": param?.site?.id || null,
            "page": to?.name || null,
            "employee_code": param?.customer?.code || null,
            "mission_id": null,
            "employee_mission_id": null,
            "reward_id": null,
            "blog_id": null,
            "mission_status": null,
            "mission_category": null,
            "reward_category": null,
            "delivery_status": null,
            "host": generateBrowserFingerprint(),
        }
        const visitLog = to?.meta?.visitLog;
        const params_map = visitLog?.params;
        const route_params = to?.params;
        if (params_map) {
            for (let key in params_map) {
                const value = params_map[key];
                const value_param = route_params[key];
                body[value] = value_param;
            }
        }

        // const test = true;
        // if (test) return;

        AxiosBackend.post("createEmployeeVisitLog", { ...body })
            .then((res) => {
                if (res) {
                    // console.log(res)
                }
            })
            .catch((err) => {
                console.log("createEmployeeVisitLog err :>> ", err);
            });
    } catch (err) { console.error("logVisit", err) }
}

async function getProfileNPS() {
    let response = null;
    try {
        response = await Axios.get("getProfileNPS")
    } catch (err) {
        console.log("getProfileNPS", err)
    }
    if (response) {
        const dataResponse = response.data;
        if (dataResponse.status == "success") {
            const userNps = store?.state?.defaultSystem?.userNps;
            const data = dataResponse?.data;
            let detail = {
                ...userNps,
                employee_id: data?.id || null,
                employee_code: data?.code || null,
                nps_alert: data?.nps_alert || false,
                nps_condition: data?.nps_condition || null,
                nps_condition_time: data?.nps_condition_time || null,
                nps_question_text: data?.nps_question_text || '',
                nps_is_use_comment_box: data?.nps_is_use_comment_box || false,
            }
            if (detail.nps_condition == 'app') {
                detail.nps_condition_time = detail.nps_condition_time || 5;
                if (detail.nps_timestamp == undefined) {
                    detail.nps_timestamp = new Date();
                }
            } else {
                delete detail['nps_timestamp'];
            }
            store.commit("defaultSystem/SET_USER_NPS", { ...detail });
        }
    }
    return response;
}

// ========== ========== Color ========== ==========
function hexToHsl(hex) {
    // แปลง HEX เป็น RGB
    let r = parseInt(hex.slice(1, 3), 16) / 255;
    let g = parseInt(hex.slice(3, 5), 16) / 255;
    let b = parseInt(hex.slice(5, 7), 16) / 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h, s, l;
    l = (max + min) / 2;

    if (max === min) {
        h = s = 0; // Grayscale
    } else {
        const diff = max - min;
        s = l > 0.5 ? diff / (2 - max - min) : diff / (max + min);
        switch (max) {
            case r:
                h = (g - b) / diff + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / diff + 2;
                break;
            case b:
                h = (r - g) / diff + 4;
                break;
        }
        h /= 6;
    }

    return {
        h: Math.round(h * 360),
        s: Math.round(s * 100),
        l: Math.round(l * 100),
    };
}
function hslToHex(h, s, l) {
    l /= 100;
    const a = (s * Math.min(l, 1 - l)) / 100;
    const f = (n) => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color)
            .toString(16)
            .padStart(2, "0"); // แปลงกลับเป็น HEX
    };
    return `#${f(0)}${f(8)}${f(4)}`;
}
function adjustHslBrightness(hexColor, adjustment) {
    const hsl = hexToHsl(hexColor);
    hsl.l = Math.min(100, Math.max(0, hsl.l + adjustment)); // ปรับความสว่าง
    return hslToHex(hsl.h, hsl.s, hsl.l);
}
function getFontColorBasedOnBg(bgColor, mode) {
    let foreColor = "#FFFFFF";
    // แปลงสี Hex ให้เป็น RGB
    let r, g, b;

    if (bgColor.startsWith("#")) {
        // Remove # และแปลง Hex เป็น RGB
        const bigint = parseInt(bgColor.slice(1), 16);
        r = (bigint >> 16) & 255;
        g = (bigint >> 8) & 255;
        b = bigint & 255;
    } else if (bgColor.startsWith("rgb")) {
        // ถ้าเป็นรูปแบบ rgb() หรือ rgba()
        const rgbValues = bgColor.match(/\d+/g);
        r = parseInt(rgbValues[0], 10);
        g = parseInt(rgbValues[1], 10);
        b = parseInt(rgbValues[2], 10);
    }

    if (r) {
        // คำนวณ Luminance
        const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
        // เลือกสีฟอนต์
        foreColor = luminance > 190 ? "#000000" : "#FFFFFF"; // ถ้าสว่าง ใช้สีดำ, ถ้ามืด ใช้สีขาว
    }

    let raw = adjustHslBrightness(
        bgColor,
        foreColor === "#000000" ? -50 : 50
    );
    if (mode == "bw") {
        raw = foreColor;
    }

    return raw;
}
// ========== ========== ========== ==========
export {
    addParamAndReload,
    detectBrowser,
    logVisit,
    detectReferrer,
    getProfileNPS,
    // ========== ========== Color ========== ==========
    hexToHsl,
    hslToHex,
    adjustHslBrightness,
    getFontColorBasedOnBg,
    // ========== ========== ========== ==========
}