<template>
  <v-app
    id="materialpro"
    :class="`${
      !$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'
    }`"
  >
    <FallingSnowTheme />

    <router-view v-show="!overlayLoading" />
    <div class="text-center">
      <v-overlay :z-index="zIndex" :value="overlayLoading" opacity="0.9">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
        <h2 class="mt-5">Please Wait...</h2>
      </v-overlay>
    </div>

    <v-overlay :z-index="999" :value="isLoading" opacity="0.5">
      <div style="display: flex; flex-direction: column; align-items: center">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
        <h2 class="mt-5">Please Wait</h2>
      </div>
    </v-overlay>

    <ModalConsent />
    <ModalNps />
  </v-app>
</template>

<script>
import ModalConsent from "@/views/Customer/ModalConsent.vue";
import ModalNps from "@/views/Customer/ModalNps.vue";
import FallingSnowTheme from "@/components/theme/falling-snow.vue";
import { mapState } from 'vuex';

export default {
  name: "App",
  components: {
    ModalConsent,
    ModalNps,
    FallingSnowTheme,
  },
  data: () => ({
    zIndex: 205,
    isLoading: false,
  }),
  provide() {
    return {
      isAppLoading: async (open, time) => {
        return new Promise((resolve) => {
          const _time = time || 0;
          setTimeout(() => {
            this.isLoading = open;
            resolve(true);
          }, _time);
        });
      },
    };
  },
  computed: {
    ...mapState("defaultSystem", ["customer"]),
    overlayLoading: {
      set() {
        this.$store.commit("defaultSystem/SET_LOADING", false);
      },
      get() {
        return this.$store.getters["defaultSystem/getLoading"];
      },
    },
  },
  watch: {
    overlayLoading: {
      handler() {
        this.overlayLoading;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.main {
  max-height: 2000px;
}
.menu-text {
  /* position: absolute; */
  z-index: 30;
}
.hamberger-menu {
  z-index: 40;
}
.display-coin {
  position: absolute;
  z-index: 50;
}
.v-list--two-line .v-list-item,
.v-list-item--two-line {
  min-height: 100px;
}

@import url("https://fonts.googleapis.com/css2?family=Athiti:wght@400;500;600&display=swap");

body {
  font-family: "Poppins", "Athiti" !important;
}
</style>
<style>
.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  color: rgba(0, 0, 0, 0.6) !important;
}
.sd-root-modern * {
  font-family: "Poppins", "Athiti" !important;
}
</style>
